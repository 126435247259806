import React from "react"
import styled from "styled-components"
import Footer from "src/storyblok/GlobalModules/Footer"
import H1 from "src/components/UI/Typography/H1"
import { Link } from "gatsby"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import Section from "src/components/UI/Layout/Section"
import bedowSymbol from "src/assets/images/bedow-symbol-404.svg"

const Wrapper = styled(Section)`
  width: 100%;
  min-height: 100vh;

  a {
    margin-right: 6px;
  }

  .symbol {
    transform: rotate(-15deg);
  }
`

const NotFoundPage = ({ pageContext }) => {
  return (
    <React.Fragment>
      <Wrapper justifyContent={["flex-start", null, null, "center"]}>
        <Container py={[4, null, null, 0]}>
          <Row
            justifyContent="space-between"
            alignItems={["flex-start", null, null, "center"]}
          >
            <Column
              width={[3 / 6, null, null, 3 / 12]}
              ml={[`${(1 / 6) * 100}%`, null, null, `${(1 / 12) * 100}%`]}
              mb={[4, null, null, 0]}
              flexDirection="column"
            >
              <img className="symbol" alt="404" src={bedowSymbol} />
            </Column>
            <Column
              width={[5 / 6, null, null, 6 / 12]}
              ml={[`${(1 / 6) * 100}%`, null, null, 0]}
              mr={[0, null, null, `${(1 / 12) * 100}%`]}
              flexDirection="column"
            >
              <Row>
                <Column
                  width={[6 / 6, null, null, 1 / 6]}
                  mt={[0, null, null, "4px"]}
                  mb={[1, null, null, 1]}
                >
                  404 Error
                </Column>
                <Column width={[6 / 6, null, null, 5 / 6]}>
                  <H1>
                    Looks like something went wrong... Try going back to our{" "}
                    <Link className="o-link" to={"/"}>
                      homepage
                    </Link>
                  </H1>
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </Wrapper>
      <Footer blok={pageContext.footer.content} />
    </React.Fragment>
  )
}

export default NotFoundPage
